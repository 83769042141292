import { useState } from "react";
import { useEffect } from "react";
import Storage from "../../services/Storage";
import "./Advert.scss";
// import useDfpSlot from "./useDfpSlots.js";
import { Bling as GPT } from "react-gpt";
import { urlConstants } from "../../utils/urlConstants";
import { useSelector } from "react-redux";
import { Roles } from "../../utils/Roles";

GPT.enableSingleRequest();

function Advert() {
  const { API_URL } = urlConstants;
  const token = Storage.getTokenInCookie("token");

  let userData = useSelector(state => state.UserReducer.userObj);

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  // };

  const [adDivId, setAdDivId] = useState("");
  const [adPath, setAdPath] = useState("");

  useEffect(() => {
    showAd();
  }, []);

  const showAd = () => {
    let googleAddApiUrl = '';
    if (userData?.role === Roles.STUDENT_NAME) {
      googleAddApiUrl = `${API_URL}/api/student/googleads/`;
    } else if (userData?.role === Roles.PARENT_NAME) {
      googleAddApiUrl = `${API_URL}/api/student/googleads/`;
    }
    fetch(googleAddApiUrl, {
      method: "GET",
      headers: {
        "x-authorization": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (userData?.role === Roles.STUDENT_NAME) {
          setAdDivId(data?.adv_detail[0]?.adv_id);
          setAdPath(data?.adv_detail[0]?.path);
        } else if (userData?.role === Roles.PARENT_NAME) {
          setAdDivId(data?.adv_detail[0]?.adv_id);
          setAdPath(data?.adv_detail[0]?.path);
        }
      });
  };

  return (
    <>
      {adDivId && adPath ?
        <div id={adDivId} className='advSlider adSize'>
          <GPT
            adUnitPath={adPath}
            slotSize={[970, 250]}
            sizeMapping={[
              {viewport: [0, 0], slot: [320, 50]},
              {viewport: [750, 0], slot: [970, 250]},
              {viewport: [1050, 0], slot: [970, 250]}
          ]}
          />
        </div>
        // <Slider {...settings} className='advSlider'>
        //   <div className="ads-main">
        //     <div id={adDivId}>
        //       <GPT adUnitPath={adPath} slotSize={[468, 60]} />
        //     </div>
        //   </div>
        //   <div className="ads-main">
        //     <img src={adpic} alt="img" />
        //   </div>
        //   <div className="ads-main">
        //     <img src={adpic} alt="img" />
        //   </div>
        // </Slider>
        : ''}

      {/* <Gpt 
        adUnit={adPath}
        name="ad-name"
        size={[468, 60]}
    /> */}
      {/* <Slider {...settings}>
        <div className="ads-main">
          <div id={adDivId}>
            <GPT adUnitPath={adPath} slotSize={[468, 60]} />
          </div>
        </div>
        <div className="ads-main">
          <img src={adpic} alt="img" />
        </div>
      </Slider> */}
    </>
  );
}

export default Advert;