import { useRef, useState } from "react";
import { useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  Button,
  Label,
  Input,
  FormGroup,
  // Tooltip,
} from "reactstrap";
import PortFolioService from "../../services/PortfolioService";
import Loading from "../Loading/Loading";
import "./Curriculum.scss";
import Switch from "react-switch";
import CommonButton from "../CommonButton/CommonButton";
import swal from "sweetalert";
import { CommonMessages } from "../../constants/CommonMessages";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { faUpload, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Storage from "../../services/Storage";
const API_URL = process.env.REACT_APP_API_URL;

function CurriculumUpload() {
  let userData = useSelector((state) => state.UserReducer.userObj);
  let history = useHistory();

  const [IELTS, setIELTS] = useState();
  const [TOEFL, setTOEFL] = useState();
  const [GRE, setGRE] = useState();
  const [GMAT, setGMAT] = useState();
  const [user, setUser] = useState(userData);

  const [recommendation, setRecommendation] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [internship, setInternship] = useState(null);
  const [educational, setEducational] = useState(null);
  const [resume, setResume] = useState(null);

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(false);

  const [uploadedFile, setUploadedFile] = useState({
    IELTS: null,
    TOEFL: null,
    GRE: null,
    GMAT: null,
    recommendation: null,
    purpose: null,
    internship: null,
    educational: null,
    resume: null,
  });
  const [uploadedFileName, setUploadedFileName] = useState({
    IELTS: "",
    TOEFL: "",
    GRE: "",
    GMAT: "",
    recommendation: "",
    purpose: "",
    internship: "",
    educational: "",
    resume: "",
  });

  // const [tooltipOpenUpload, setTooltipOpenUpload] = useState({
  //   IELTS: false,
  //   TOEFL: false,
  //   GRE: false,
  //   GMAT: false,
  //   recommendation: false,
  //   purpose: false,
  //   internship: false,
  //   educational: false,
  //   resume: false,
  // });

  // const [tooltipOpenPreview, setTooltipOpenPreview] = useState({
  //   IELTS: false,
  //   TOEFL: false,
  //   GRE: false,
  //   GMAT: false,
  //   recommendation: false,
  //   purpose: false,
  //   internship: false,
  //   educational: false,
  //   resume: false,
  // });

  // const toggleTooltipUpload = (exam) => {
  //   setTooltipOpenUpload((prevState) => ({
  //     ...prevState,
  //     [exam]: !prevState[exam],
  //   }));
  // };

  // const toggleTooltipPreview = (exam) => {
  //   setTooltipOpenPreview((prevState) => ({
  //     ...prevState,
  //     [exam]: !prevState[exam],
  //   }));
  // };

  const handleFileUpload = async (exam, documentType) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf,.doc,.docx,.jpg,.jpeg,.png";

    input.onchange = async (e) => {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('user_id', user?.id);
      formData.append('type', documentType);
      let token = Storage.getTokenInCookie() ? Storage.getTokenInCookie() : "";
      formData.append('token', token); // Append additional data like token
  
      setLoading(true);
      try {
        const headers = {
          'x-authorization': `Bearer ${token}`
        };
        const response = await fetch(`${API_URL}/api/student/attachment_curriculum/`, {
          method: 'POST',
          headers,
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        setLoading(false);
        console.log("Response from server:", data);
        const uploadedFileURL = data?.Status?.file_url;

        swal({
          text: "File Uploaded Successfully.",
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })

        setUploadedFile({ ...uploadedFile, [exam]: uploadedFileURL });
        setUploadedFileName({ ...uploadedFileName, [exam]: file.name });
      } catch (error) {
        setLoading(false);
        console.error("Error from the service", error);
      }
    };
  
    input.click();
  };

  const handleRemoveFile = (exam) => {
    setUploadedFile({ ...uploadedFile, [exam]: null });
    setUploadedFileName({ ...uploadedFileName, [exam]: "" });
  };

  const handleRecommendationChange = (e) => {
    setRecommendation(e.target.value);
    if (e.target.value === "No") {
      handleRemoveFile("recommendation");
    }
  };

  const handlePurposeChange = (e) => {
    setPurpose(e.target.value);
    if (e.target.value === "No") {
      handleRemoveFile("purpose");
    }
  };

  const handleInternshipChange = (e) => {
    setInternship(e.target.value);
    if (e.target.value === "No") {
      handleRemoveFile("internship");
    }
  };

  const handleEducationalChange = (e) => {
    setEducational(e.target.value);
    if (e.target.value === "No") {
      handleRemoveFile("educational");
    }
  };

  const handleResumeChange = (e) => {
    setResume(e.target.value);
    if (e.target.value === "No") {
      handleRemoveFile("resume");
    }
  };

  const renderFileUpload = (exam, score, setScore, documentType) => (
    <FormGroup className="position-relative">
      <Row>
        <Col xs="12" lg="6">
          <Label for={`${exam}Score`} className="labelBold">
            {language === true ? `${exam} Puntaje` : `${exam} Score`}
          </Label>
        </Col>
        <Col xs="12" lg="6">
          <Label for={`${exam}Score`} className="labelBold">
            {language === true
              ? `Subir ${exam} Tarjeta de puntuación`
              : `Upload ${exam} Score Card`}
          </Label>
        </Col>
      </Row>
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <Col xs="12" lg="6" className="pl-0 pr-0">
          <Input
            required
            type="number"
            name={`${exam}Score`}
            id={`${exam}Score`}
            placeholder={
              language === true
                ? `Entra tu ${exam} puntaje`
                : `Enter your ${exam} score`
            }
            value={score}
            onChange={(e) => setScore(e.target.value)}
            autoComplete="off"
            maxLength={5}
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs="12" lg="6" style={{ display: "flex", alignItems: "center" }}>
          {!uploadedFile[exam] && (
            <>
              <div
                id={`uploadIcon-${exam}`}
                onClick={() => handleFileUpload(exam, documentType)}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faUpload} size="lg" />
              </div>
              {/* <Tooltip
                placement="top"
                isOpen={tooltipOpenUpload[exam]}
                target={`uploadIcon-${exam}`}
                toggle={() => toggleTooltipUpload(exam)}
              >
                {language === true ? "Cargar documento" : "Upload Document"}
              </Tooltip> */}
            </>
          )}
          {uploadedFile[exam] && (
            <>
              <span style={{ marginLeft: "10px" }}>
                {uploadedFileName[exam]}
              </span>
              <a
                href={uploadedFile[exam]}
                target="_blank"
                rel="noopener noreferrer"
                id={`previewIcon-${exam}`}
                style={{
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
              >
                <FontAwesomeIcon icon={faEye} size="lg" />
              </a>
              {/* <Tooltip
                placement="top"
                isOpen={tooltipOpenPreview[exam]}
                target={`previewIcon-${exam}`}
                toggle={() => toggleTooltipPreview(exam)}
              >
                {language === true
                  ? "Vista previa del documento"
                  : "Preview Document"}
              </Tooltip> */}
              <div
                onClick={() => handleRemoveFile(exam)}
                style={{ cursor: "pointer", color: "red", marginLeft: "16px" }}
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </div>
            </>
          )}
        </Col>
      </div>
    </FormGroup>
  );

  const handleLanguage = (checked) => {
    const selectedLanguage = checked ? "es" : "en";
    setLanguage(checked);
  };

  useEffect(() => {
    getCurriculumUpload();
  }, []);

  const getCurriculumUpload = () => {
    setLoading(true);
    PortFolioService.getCurriculumUpload()
      .then((data) => {
        console.log("data", data);
        const curriculums = data?.data || {};
        setIELTS(curriculums?.ielts_score || "");
        setTOEFL(curriculums?.toefl_score || "");
        setGRE(curriculums?.gre_score || "");
        setGMAT(curriculums?.gmat_score || "");
        setRecommendation(curriculums?.letter_of_recommendation || "");
        setPurpose(curriculums?.statement_of_purpose || "");
        setInternship(curriculums?.research_papers_internships || "");
        setEducational(curriculums?.all_education_transcript || "");
        setResume(curriculums?.cv_resume || "");
  
        setUploadedFile({
          IELTS: curriculums?.ielts_scorecard || "",
          TOEFL: curriculums?.toefl_scorecard || "",
          GRE: curriculums?.gre_scorecard || "",
          GMAT: curriculums?.gmat_scorecard || "",
          recommendation: curriculums?.lor_document || "",
          purpose: curriculums?.sop_document || "",
          internship: curriculums?.rpi_document || "",
          educational: curriculums?.aet_document || "",
          resume: curriculums?.cv_resume_document || "",
        });
  
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error from the service", error);
      });
  };
  

  const handleCurriculumSubmit = () => {

    if(!recommendation){
      swal("", "Please select letter of recommendation.", "error");
      return;
    }
    if(!purpose){
      swal("", "Please select statement of purpose.", "error");
      return;
    }
    if(!internship){
      swal("", "Please select research papers / internships.", "error");
      return;
    }
    if(!educational){
      swal("", "Please select all education transcript.", "error");
      return;
    }
    if(!resume){
      swal("", "Please select cv or resume.", "error");
      return;
    }
    const formData = new FormData();
    
    formData.append('user_id', user?.id);
    if(IELTS){
    formData.append('ielts_score', IELTS);
    }

    if(uploadedFile?.IELTS){
    formData.append('ielts_scorecard', uploadedFile?.IELTS);
    }

    if(TOEFL){
    formData.append('toefl_score', TOEFL);
    }

    if(uploadedFile?.TOEFL){
    formData.append('toefl_scorecard', uploadedFile?.TOEFL);
    }

    if(GRE){
    formData.append('gre_score', GRE);
    }

    if(uploadedFile?.GRE){
    formData.append('gre_scorecard', uploadedFile?.GRE);
    }
    
    if(GMAT){
    formData.append('gmat_score', GMAT);
    }

    if(uploadedFile?.GMAT){
    formData.append('gmat_scorecard', uploadedFile?.GMAT);
    }

    if(recommendation){
    formData.append('letter_of_recommendation', recommendation);
    } 

    if(uploadedFile?.recommendation){
    formData.append('lor_document', uploadedFile?.recommendation);
    }

    if(purpose){
    formData.append('statement_of_purpose', purpose);
    }

    if(uploadedFile?.purpose){
    formData.append('sop_document', uploadedFile?.purpose);
    }
  
    if(internship){
    formData.append('research_papers_internships', internship);
    }
   
    if(uploadedFile?.internship){
    formData.append('rpi_document', uploadedFile?.internship);
    }
    
    if(educational){
    formData.append('all_education_transcript', educational);
    }
    
    if(uploadedFile?.educational){
    formData.append('aet_document', uploadedFile?.educational);
    }
  
    if(resume){
    formData.append('cv_resume', resume);
    }
    
    if(uploadedFile?.resume){
    formData.append('cv_resume_document', uploadedFile?.resume);
    }
  
    setLoading(true);
    
    PortFolioService.saveCurriculumUpload(formData)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          swal("", CommonMessages.curriculumSaved, "success");
          if (user) {
            user.filled_curriculum = true;
            localStorage.setItem("user", JSON.stringify(user));
          } else {
            console.error("User not found in localStorage");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("error from the service", error);
        if (error?.response?.status === 400) {
          swal("", error?.response?.data?.message, "error");
        } else if (error?.response?.status === 500) {
          swal("", error?.response?.data?.message, "error");
        }
      });
  };
  

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h1 className="title-main">My Curriculum</h1>
            <Switch
              onChange={handleLanguage}
              checked={language}
              width={52}
              height={24}
              handleDiameter={18}
              onColor="#28131A"
              offColor="#049804"
              uncheckedIcon={<div className="switchIncon uncheckIcon">EN</div>}
              checkedIcon={<div className="switchIncon checkIcon">ES</div>}
              className="langSwitch"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mx-auto">
          <Card className="main-card mb-3 interest-main">
            <CardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <Row>
                    <Col xs="12" lg="6">
                      {renderFileUpload("IELTS", IELTS, setIELTS, 'ielts_scorecard')}
                    </Col>
                    <Col xs="12" lg="6">
                      {renderFileUpload("TOEFL", TOEFL, setTOEFL, 'toefl_scorecard')}
                    </Col>
                    <Col xs="12" lg="6">
                      {renderFileUpload("GRE", GRE, setGRE, 'gre_scorecard')}
                    </Col>
                    <Col xs="12" lg="6">
                      {renderFileUpload("GMAT", GMAT, setGMAT, 'gmat_scorecard')}
                    </Col>
                    <Col xs="12" lg="6">
                      <Row>
                        <Col lg={recommendation === "Yes" ? "6" : "12"}>
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Carta de recomendación"
                                : "Letter of Recommendation"}
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="recommendation"
                                    value="Yes"
                                    checked={recommendation === "Yes"}
                                    onChange={handleRecommendationChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="recommendation"
                                    value="No"
                                    checked={recommendation === "No"}
                                    onChange={handleRecommendationChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>

                        {recommendation === "Yes" && (
                          <Col xs="12" lg="6">
                            <FormGroup className="position-relative">
                              <Label for="fileUpload" className="labelBold">
                                {language === true
                                  ? "Subir carta de recomendación"
                                  : "Upload Letter of Recommendation"}
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {!uploadedFile["recommendation"] && (
                                  <>
                                    <div
                                      id={`uploadIcon-recommendation`}
                                      onClick={() =>
                                        handleFileUpload("recommendation", "lor_document")
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faUpload}
                                        size="lg"
                                      />
                                    </div>
                                    {/* <Tooltip
                                      placement="top"
                                      isOpen={
                                        tooltipOpenUpload["recommendation"]
                                      }
                                      target={`uploadIcon-recommendation`}
                                      toggle={() =>
                                        toggleTooltipUpload("recommendation")
                                      }
                                    >
                                      {language === true
                                        ? "Cargar documento"
                                        : "Upload Document"}
                                    </Tooltip> */}
                                  </>
                                )}
                                {uploadedFile["recommendation"] && (
                                  <>
                                    <span style={{ marginLeft: "10px" }}>
                                      {uploadedFileName["recommendation"]}
                                    </span>
                                    <a
                                      href={uploadedFile["recommendation"]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id={`previewIcon-recommendation`}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEye} size="lg" />
                                    </a>
                                    {/* <Tooltip
                                      placement="top"
                                      isOpen={
                                        tooltipOpenPreview["recommendation"]
                                      }
                                      target={`previewIcon-recommendation`}
                                      toggle={() =>
                                        toggleTooltipPreview("recommendation")
                                      }
                                    >
                                      {language === true
                                        ? "Vista previa del documento"
                                        : "Preview Document"}
                                    </Tooltip> */}
                                    <div
                                      onClick={() =>
                                        handleRemoveFile("recommendation")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        size="lg"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col xs="12" lg="6">
                      <Row>
                        <Col lg={purpose === "Yes" ? "6" : "12"}>
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Declaración de propósito"
                                : "Statement of Purpose"}
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="purpose"
                                    value="Yes"
                                    checked={purpose === "Yes"}
                                    onChange={handlePurposeChange}
                                  />{" "}
                                  {language === true ? "Sí": "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="purpose"
                                    value="No"
                                    checked={purpose === "No"}
                                    onChange={handlePurposeChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        {purpose === "Yes" && (
                          <Col xs="12" lg="6">
                            <FormGroup className="position-relative">
                              <Label for="fileUpload" className="labelBold">
                                {language === true
                                  ? "Cargar declaración de propósito"
                                  : "Upload Statement of Purpose"}
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {!uploadedFile["purpose"] && (
                                  <div
                                    id={`uploadIcon-purpose`}
                                    onClick={() => handleFileUpload("purpose", "sop_document")}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      size="lg"
                                    />
                                  </div>
                                )}
                                {/* <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpenUpload["purpose"]}
                                  target={`uploadIcon-purpose`}
                                  toggle={() => toggleTooltipUpload("purpose")}
                                >
                                  {language === true
                                    ? "Cargar documento"
                                    : "Upload Document" }
                                </Tooltip> */}
                                {uploadedFile["purpose"] && (
                                  <>
                                    <span style={{ marginLeft: "10px" }}>
                                      {uploadedFileName["purpose"]}
                                    </span>
                                    <a
                                      href={uploadedFile["purpose"]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id={`previewIcon-purpose`}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEye} size="lg" />
                                    </a>
                                    {/* <Tooltip
                                      placement="top"
                                      isOpen={tooltipOpenPreview["purpose"]}
                                      target={`previewIcon-purpose`}
                                      toggle={() =>
                                        toggleTooltipPreview("purpose")
                                      }
                                    >
                                      {language === true
                                        ? "Vista previa del documento"
                                        : "Preview Document"}
                                    </Tooltip> */}
                                    <div
                                      onClick={() =>
                                        handleRemoveFile("purpose")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        size="lg"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" lg="6">
                      <Row>
                        <Col lg={internship === "Yes" ? "6" : "12"}>
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Trabajos de investigación publicados/pasantías"
                                : "Published Research Papers / Internships"}
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="internship"
                                    value="Yes"
                                    checked={internship === "Yes"}
                                    onChange={handleInternshipChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="internship"
                                    value="No"
                                    checked={internship === "No"}
                                    onChange={handleInternshipChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        {internship === "Yes" && (
                          <Col xs="12" lg="6">
                            <FormGroup className="position-relative">
                              <Label for="fileUpload" className="labelBold">
                                {language === true
                                  ? "Cargar trabajos de investigación publicados/pasantías" 
                                  : "Upload Published Research Papers / Internships"}
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {!uploadedFile["internship"] && (
                                  <div
                                    id={`uploadIcon-internship`}
                                    onClick={() =>
                                      handleFileUpload("internship", "rpi_document")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      size="lg"
                                    />
                                  </div>
                                )}
                                {/* <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpenUpload["internship"]}
                                  target={`uploadIcon-internship`}
                                  toggle={() =>
                                    toggleTooltipUpload("internship")
                                  }
                                >
                                  {language === true
                                    ? "Cargar documento"
                                    : "Upload Document" }
                                </Tooltip> */}
                                {uploadedFile["internship"] && (
                                  <>
                                    <span style={{ marginLeft: "10px" }}>
                                      {uploadedFileName["internship"]}
                                    </span>
                                    <a
                                      href={uploadedFile["internship"]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id={`previewIcon-internship`}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEye} size="lg" />
                                    </a>
                                    {/* <Tooltip
                                      placement="top"
                                      isOpen={tooltipOpenPreview["internship"]}
                                      target={`previewIcon-internship`}
                                      toggle={() =>
                                        toggleTooltipPreview("internship")
                                      }
                                    >
                                      {language === true
                                        ? "Vista previa del documento"
                                        : "Preview Document" }
                                    </Tooltip> */}
                                    <div
                                      onClick={() =>
                                        handleRemoveFile("internship")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        size="lg"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col xs="12" lg="6">
                      <Row>
                        <Col lg={educational === "Yes" ? "6" : "12"}>
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "Todas las transcripciones educativas"
                                : "All Education Transcripts"}
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="educational"
                                    value="Yes"
                                    checked={educational === "Yes"}
                                    onChange={handleEducationalChange}
                                  />{" "}
                                  {language === true ? "Sí" : "Yes" }
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="educational"
                                    value="No"
                                    checked={educational === "No"}
                                    onChange={handleEducationalChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        {educational === "Yes" && (
                          <Col xs="12" lg="6">
                            <FormGroup className="position-relative">
                              <Label for="fileUpload" className="labelBold">
                                {language === true
                                  ? "Cargar todas las transcripciones educativas"
                                  : "Upload All Education Transcripts"}
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {!uploadedFile["educational"] && (
                                  <div
                                    id={`uploadIcon-educational`}
                                    onClick={() =>
                                      handleFileUpload("educational", "aet_document")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      size="lg"
                                    />
                                  </div>
                                )}
                                {/* <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpenUpload["educational"]}
                                  target={`uploadIcon-educational`}
                                  toggle={() =>
                                    toggleTooltipUpload("educational")
                                  }
                                >
                                  {language === true
                                    ? "Cargar documento" 
                                    : "Upload Document"}
                                </Tooltip> */}
                                {uploadedFile["educational"] && (
                                  <>
                                    <span style={{ marginLeft: "10px" }}>
                                      {uploadedFileName["educational"]}
                                    </span>
                                    <a
                                      href={uploadedFile["educational"]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id={`previewIcon-educational`}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEye} size="lg" />
                                    </a>
                                    {/* <Tooltip
                                      placement="top"
                                      isOpen={tooltipOpenPreview["educational"]}
                                      target={`previewIcon-educational`}
                                      toggle={() =>
                                        toggleTooltipPreview("educational")
                                      }
                                    >
                                      {language === true
                                        ? "Preview Document"
                                        : "Vista previa del documento"}
                                    </Tooltip> */}
                                    <div
                                      onClick={() =>
                                        handleRemoveFile("educational")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        size="lg"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" lg="6">
                      <Row>
                        <Col lg={resume === "Yes" ? "6" : "12"}>
                          <FormGroup className="position-relative">
                            <Label
                              className="labelBold"
                              style={{ marginBottom: "20px" }}
                            >
                              {language === true
                                ? "CV o currículum"
                                : "CV or Resume" }
                            </Label>
                            <div>
                              <FormGroup
                                check
                                inline
                                style={{ marginRight: "50px" }}
                              >
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="resume"
                                    value="Yes"
                                    checked={resume === "Yes"}
                                    onChange={handleResumeChange}
                                  />{" "}
                                  {language === true ? "Sí":"Yes"}
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="resume"
                                    value="No"
                                    checked={resume === "No"}
                                    onChange={handleResumeChange}
                                  />{" "}
                                  {language === true ? "No" : "No"}
                                </Label>
                              </FormGroup>
                            </div>
                          </FormGroup>
                        </Col>
                        {resume === "Yes" && (
                          <Col xs="12" lg="6">
                            <FormGroup className="position-relative">
                              <Label for="fileUpload" className="labelBold">
                                {language === true
                                  ? "Subir CV o Currículum"
                                  : "Upload CV or Resume"}
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {!uploadedFile["resume"] && (
                                  <div
                                    id={`uploadIcon-resume`}
                                    onClick={() => handleFileUpload("resume", "cv_resume_document")}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      size="lg"
                                    />
                                  </div>
                                )}
                                {/* <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpenUpload["resume"]}
                                  target={`uploadIcon-resume`}
                                  toggle={() => toggleTooltipUpload("resume")}
                                >
                                  {language === true
                                    ? "Upload Document"
                                    : "Cargar documento"}
                                </Tooltip> */}
                                {uploadedFile["resume"] && (
                                  <>
                                    <span style={{ marginLeft: "10px" }}>
                                      {uploadedFileName["resume"]}
                                    </span>
                                    <a
                                      href={uploadedFile["resume"]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id={`previewIcon-resume`}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEye} size="lg" />
                                    </a>
                                    {/* <Tooltip
                                      placement="top"
                                      isOpen={tooltipOpenPreview["resume"]}
                                      target={`previewIcon-resume`}
                                      toggle={() =>
                                        toggleTooltipPreview("resume")
                                      }
                                    >
                                      {language === true
                                        ? "Preview Document"
                                        : "Vista previa del documento"}
                                    </Tooltip> */}
                                    <div
                                      onClick={() => handleRemoveFile("resume")}
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        size="lg"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">&nbsp;</Col>
                    <Col lg="6">
                      <div className="d-flex justify-content-end btnWrapper">
                        <div className="btnInner">
                          <CommonButton
                            name="Back"
                            btnClass="primaryBtn w-100 passionBtn"
                            onClick={() => history.goBack()}
                          />
                        </div>
                        <div className="btnInner">
                          <CommonButton
                            name="Save Curriculum"
                            btnClass="primaryBtn w-100 passionBtn"
                            onClick={() => handleCurriculumSubmit()}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CurriculumUpload;
