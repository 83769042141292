import { useRef, useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  Label,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import Loading from "../Loading/Loading";
import "./Reflection.scss";
import CommonButton from "../CommonButton/CommonButton";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import GetAdminService from "../../services/admin/GetAdminService";
import CustomLoader from "../customLoader/CustomLoader";
import StudentGoalService from "../../services/StudentGoalService";
import PortFolioService from "../../services/PortfolioService";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

function Reflection() {
  let userData = useSelector((state) => state.UserReducer.userObj);
  let history = useHistory();

  const [className1, setClassName1] = useState("");
  const [className2, setClassName2] = useState("");
  const [className3, setClassName3] = useState("");
  const [className4, setClassName4] = useState("");
  const [className5, setClassName5] = useState("");
  const [className6, setClassName6] = useState("");
  const [activeInput, setActiveInput] = useState(null);

  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState([]);

  const firstClassRef = useRef();
  const secondClassRef = useRef();
  const thirdClassRef = useRef();
  const fourthClassRef = useRef();
  const fifthClassRef = useRef();
  const sixthClassRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolId, setSchoolId] = useState(null);
  const [studentListData, setStudentListData] = useState([]);

  const [selectedOptionHow1, setSelectedOptionHow1] = useState("Select");
  const [selectedOptionHow2, setSelectedOptionHow2] = useState("Select");
  const [selectedOptionHow3, setSelectedOptionHow3] = useState("Select");
  const [selectedOptionHow4, setSelectedOptionHow4] = useState("Select");
  const [selectedOptionHow5, setSelectedOptionHow5] = useState("Select");
  const [selectedOptionHow6, setSelectedOptionHow6] = useState("Select");

  const [selectedOptionWhen1, setSelectedOptionWhen1] = useState("Select");
  const [selectedOptionWhen2, setSelectedOptionWhen2] = useState("Select");
  const [selectedOptionWhen3, setSelectedOptionWhen3] = useState("Select");
  const [selectedOptionWhen4, setSelectedOptionWhen4] = useState("Select");
  const [selectedOptionWhen5, setSelectedOptionWhen5] = useState("Select");
  const [selectedOptionWhen6, setSelectedOptionWhen6] = useState("Select");

  const [highSchool, sethighSchool] = useState("");
  const [doing, setDoing] = useState("");
  const [help, setHelp] = useState("");
  const [success, setSuccess] = useState("");
  const [difficulties, setDifficulties] = useState("");
  const [challenges, setChallenges] = useState("");
  const [threeGoals, setThreeGoals] = useState("");

  const getClassesList = (goal_id, school_id, value) => {
    setSchoolNameList([]);
    setShowSchoolNameContainer(true);
    setSchoolNameLoading(true);
    GetAdminService.getClassesList(goal_id, school_id, value)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.class_names || []);
        setSchoolNameLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const handleSchoolName = (e, index) => {
    clearTimeout(timeoutRef.current);
    const value = e.target.value;

    setActiveInput(index);
    switch (index) {
      case 1:
        setClassName1(value);
        break;
      case 2:
        setClassName2(value);
        break;
      case 3:
        setClassName3(value);
        break;
      case 4:
        setClassName4(value);
        break;
      case 5:
        setClassName5(value);
        break;
      case 6:
        setClassName6(value);
        break;
      default:
        break;
    }

    if (!value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
      setStudentListData([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        const goal_id = index <= 3 ? "75" : "45";
        getClassesList(goal_id, userData?.school_id, value);
      }, 500);
    }
  };

  const handleSelectedSchoolName = (item, index) => {
    switch (activeInput) {
      case 1:
        setClassName1(item.name);
        break;
      case 2:
        setClassName2(item.name);
        break;
      case 3:
        setClassName3(item.name);
        break;
      case 4:
        setClassName4(item.name);
        break;
      case 5:
        setClassName5(item.name);
        break;
      case 6:
        setClassName6(item.name);
        break;
      default:
        break;
    }

    setSchoolId(item?.id);
    setShowSchoolNameContainer(false);
    setSchoolNameList([item]);
  };

  const handleImageClick = () => {
    let obj = {
      user_id: userData?.id,
    };
    setLoading(true);
    StudentGoalService.getPdfReflection(obj)
      .then((data) => {
        console.log("data from the service", data);
        if (data?.data?.url) {
          window.open(data.data.url, "_blank");
        } else {
          console.error("No URL found in the response");
          swal("", "No URL found in the response", "warning");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
        const errorMessage =
          error?.response?.data?.error || "Something went wrong";
        swal("", errorMessage, "error");
      });
  };

  useEffect(() => {
    getReflection();
  }, []);

  const getReflection = () => {
    setLoading(true);
    PortFolioService.getReflection()
      .then((data) => {
        const reflections = data?.data?.reflections || [];
        if (reflections?.basic_class_1) {
          setClassName1(reflections?.basic_class_1);
        } else {
          setClassName1("");
        }
        if (reflections?.basic_class_2) {
          setClassName2(reflections?.basic_class_2);
        } else {
          setClassName2("");
        }
        if (reflections?.basic_class_3) {
          setClassName3(reflections?.basic_class_3);
        } else {
          setClassName3("");
        }
        if (reflections?.uc_class_1) {
          setClassName4(reflections?.uc_class_1);
        } else {
          setClassName4("");
        }
        if (reflections?.uc_class_2) {
          setClassName5(reflections?.uc_class_2);
        } else {
          setClassName5("");
        }
        if (reflections?.uc_class_3) {
          setClassName6(reflections?.uc_class_3);
        } else {
          setClassName6("");
        }
        if (reflections?.basic_how_1) {
          setSelectedOptionHow1(reflections?.basic_how_1);
        } else {
          setSelectedOptionHow1("Select");
        }
        if (reflections?.basic_how_2) {
          setSelectedOptionHow2(reflections?.basic_how_2);
        } else {
          setSelectedOptionHow2("Select");
        }
        if (reflections?.basic_how_3) {
          setSelectedOptionHow3(reflections?.basic_how_3);
        } else {
          setSelectedOptionHow3("Select");
        }
        if (reflections?.uc_how_1) {
          setSelectedOptionHow4(reflections?.uc_how_1);
        } else {
          setSelectedOptionHow4("Select");
        }
        if (reflections?.uc_how_2) {
          setSelectedOptionHow5(reflections?.uc_how_2);
        } else {
          setSelectedOptionHow5("Select");
        }
        if (reflections?.uc_how_3) {
          setSelectedOptionHow6(reflections?.uc_how_3);
        } else {
          setSelectedOptionHow6("Select");
        }

        if (reflections?.basic_when_1) {
          console.log("reflections?.basic_when_1", reflections?.basic_when_1);
          setSelectedOptionWhen1(reflections?.basic_when_1);
        } else {
          setSelectedOptionWhen1("Select");
        }
        if (reflections?.basic_when_2) {
          setSelectedOptionWhen2(reflections?.basic_when_2);
        } else {
          setSelectedOptionWhen2("Select");
        }
        if (reflections?.basic_when_3) {
          setSelectedOptionWhen3(reflections?.basic_when_3);
        } else {
          setSelectedOptionWhen3("Select");
        }
        if (reflections?.uc_when_1) {
          setSelectedOptionWhen4(reflections?.uc_when_1);
        } else {
          setSelectedOptionWhen4("Select");
        }
        if (reflections?.uc_when_2) {
          setSelectedOptionWhen5(reflections?.uc_when_2);
        } else {
          setSelectedOptionWhen5("Select");
        }
        if (reflections?.uc_when_3) {
          setSelectedOptionWhen6(reflections?.uc_when_3);
        } else {
          setSelectedOptionWhen6("Select");
        }

        if (reflections?.class_performance) {
          setDoing(reflections?.class_performance);
        } else {
          setDoing("");
        }
        if (reflections?.advisor_support) {
          setHelp(reflections?.advisor_support);
        } else {
          setHelp("");
        }
        if (reflections?.support_from_student) {
          setSuccess(reflections?.support_from_student);
        } else {
          setSuccess("");
        }
        if (reflections?.difficulties_in_classes) {
          setDifficulties(reflections?.difficulties_in_classes);
        } else {
          setDifficulties("");
        }
        if (reflections?.advisor_support) {
          setChallenges(reflections?.advisor_support);
        } else {
          setChallenges("");
        }
        if (reflections?.top_three_goals) {
          setThreeGoals(reflections?.top_three_goals);
        } else {
          setThreeGoals("");
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error from the service", error);
      });
  };

  const handleReflectionSubmit = () => {
    let obj = {
      reflection: {
        user_id: userData?.id,
        basic_class_1: className1 || "",
        basic_how_1: selectedOptionHow1 !== "Select" ? selectedOptionHow1 : "",
        basic_when_1:
          selectedOptionWhen1 !== "Select" ? selectedOptionWhen1 : "",
        basic_class_2: className2 || "",
        basic_how_2: selectedOptionHow2 !== "Select" ? selectedOptionHow2 : "",
        basic_when_2:
          selectedOptionWhen2 !== "Select" ? selectedOptionWhen2 : "",
        basic_class_3: className3 || "",
        basic_how_3: selectedOptionHow3 !== "Select" ? selectedOptionHow3 : "",
        basic_when_3:
          selectedOptionWhen3 !== "Select" ? selectedOptionWhen3 : "",
        uc_class_1: className4 || "",
        uc_how_1: selectedOptionHow4 !== "Select" ? selectedOptionHow4 : "",
        uc_when_1: selectedOptionWhen4 !== "Select" ? selectedOptionWhen4 : "",
        uc_class_2: className5 || "",
        uc_how_2: selectedOptionHow5 !== "Select" ? selectedOptionHow5 : "",
        uc_when_2: selectedOptionWhen5 !== "Select" ? selectedOptionWhen5 : "",
        uc_class_3: className6 || "",
        uc_how_3: selectedOptionHow6 !== "Select" ? selectedOptionHow6 : "",
        uc_when_3: selectedOptionWhen6 !== "Select" ? selectedOptionWhen6 : "",
        post_high_school_plans: highSchool || "",
        class_performance: doing || "",
        support_from_advisor: help || "",
        support_from_student: success || "",
        difficulties_in_classes: difficulties || "",
        advisor_support: challenges || "",
        top_three_goals: threeGoals || "",
      },
    };
    setLoading(true);
    StudentGoalService.saveReflection(obj)
      .then((data) => {
        console.log("data from the service", data);
        setLoading(false);
        if (data?.data?.success) {
          swal({
            text: "Reflection Saved",
            icon: "success",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          });
        }
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
        const errorMessage =
          error?.response?.data?.error || "Something went wrong";
        swal("", errorMessage, "error");
      });
  };

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h1 className="title-main">My Reflections</h1>
            <button
              onClick={handleImageClick}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 20px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <img
                src={IMG_URL + "pdf.png"}
                alt="Download"
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
              />
              PDF
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mx-auto">
          <Card className="main-card mb-3 interest-main">
            <CardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <Row>
                    <Col lg="12">
                      <Label style={{ fontWeight: "bold", fontSize: "15px" }}>
                        • Basic Graduation Requirements -
                      </Label>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          First class that needs remediation
                        </Label>
                        <div>
                          <Input
                            ref={firstClassRef}
                            className="search-student-school-input"
                            type="text"
                            placeholder="Type your class remediation"
                            value={className1}
                            onChange={(e) => handleSchoolName(e, 1)}
                            autoComplete="off"
                          />
                          {activeInput === 1 &&
                            showSchoolNameContainer &&
                            schoolNameList.length > 0 && (
                              <ul
                                className="maintain-group-student-dropdown-menu"
                                style={{
                                  width: "100%",
                                  maxWidth: "740px",
                                  maxHeight: "320px",
                                  overflowY: "scroll",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  boxSizing: "border-box",
                                }}
                              >
                                {schoolNameLoading && <CustomLoader />}
                                {schoolNameList.map((item, index) => (
                                  <li
                                    onClick={() =>
                                      handleSelectedSchoolName(item, 1)
                                    }
                                    key={index}
                                    className="flex admin-school-list cursor"
                                  >
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How do you take this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionHow1}
                          onChange={(e) =>
                            setSelectedOptionHow1(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="I'm taking the course by myself">
                            I'm taking the course by myself
                          </option>
                          <option value="I'm getting tutoring">
                            I'm getting tutoring
                          </option>
                          <option value="I have enrolled in an outside program">
                            I have enrolled in an outside program
                          </option>
                          <option value="I need help taking the class">
                            I need help taking the class
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          When do you complete this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionWhen1}
                          onChange={(e) =>
                            setSelectedOptionWhen1(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="Now">Now</option>
                          <option value="Next semester">Next Semester</option>
                          <option value="Summer">Summer</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          Second class that needs remediation
                        </Label>
                        <div>
                          <Input
                            ref={secondClassRef}
                            className="search-student-school-input"
                            type="text"
                            placeholder="Type your class remediation"
                            value={className2}
                            onChange={(e) => handleSchoolName(e, 2)}
                            autoComplete="off"
                          />
                          {activeInput === 2 &&
                            showSchoolNameContainer &&
                            schoolNameList.length > 0 && (
                              <ul
                                className="maintain-group-student-dropdown-menu"
                                style={{
                                  width: "100%",
                                  maxWidth: "740px",
                                  maxHeight: "320px",
                                  overflowY: "scroll",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  boxSizing: "border-box",
                                }}
                              >
                                {schoolNameLoading && <CustomLoader />}
                                {schoolNameList.map((item, index) => (
                                  <li
                                    onClick={() =>
                                      handleSelectedSchoolName(item, 2)
                                    }
                                    key={index}
                                    className="flex admin-school-list cursor"
                                  >
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How do you take this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionHow2}
                          onChange={(e) =>
                            setSelectedOptionHow2(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="I'm taking the course by myself">
                            I'm taking the course by myself
                          </option>
                          <option value="I'm getting tutoring">
                            I'm getting tutoring
                          </option>
                          <option value="I have enrolled in an outside program">
                            I have enrolled in an outside program
                          </option>
                          <option value="I need help taking the class">
                            I need help taking the class
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          When do you complete this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionWhen2}
                          onChange={(e) =>
                            setSelectedOptionWhen2(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="Now">Now</option>
                          <option value="Next semester">Next Semester</option>
                          <option value="Summer">Summer</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          Third class that needs remediation
                        </Label>
                        <div>
                          <Input
                            ref={thirdClassRef}
                            className="search-student-school-input"
                            type="text"
                            placeholder="Type your class remediation"
                            value={className3}
                            onChange={(e) => handleSchoolName(e, 3)}
                            autoComplete="off"
                          />
                          {activeInput === 3 &&
                            showSchoolNameContainer &&
                            schoolNameList.length > 0 && (
                              <ul
                                className="maintain-group-student-dropdown-menu"
                                style={{
                                  width: "100%",
                                  maxWidth: "740px",
                                  maxHeight: "320px",
                                  overflowY: "scroll",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  boxSizing: "border-box",
                                }}
                              >
                                {schoolNameLoading && <CustomLoader />}
                                {schoolNameList.map((item, index) => (
                                  <li
                                    onClick={() =>
                                      handleSelectedSchoolName(item, 3)
                                    }
                                    key={index}
                                    className="flex admin-school-list cursor"
                                  >
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How do you take this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionHow3}
                          onChange={(e) =>
                            setSelectedOptionHow3(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="I'm taking the course by myself">
                            I'm taking the course by myself
                          </option>
                          <option value="I'm getting tutoring">
                            I'm getting tutoring
                          </option>
                          <option value="I have enrolled in an outside program">
                            I have enrolled in an outside program
                          </option>
                          <option value="I need help taking the class">
                            I need help taking the class
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          When do you complete this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionWhen3}
                          onChange={(e) =>
                            setSelectedOptionWhen3(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="Now">Now</option>
                          <option value="Next semester">Next Semester</option>
                          <option value="Summer">Summer</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Label style={{ fontWeight: "bold", fontSize: "15px" }}>
                        • UC/CSU Eligibility -
                      </Label>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          First class that needs remediation
                        </Label>
                        <div>
                          <Input
                            ref={fourthClassRef}
                            className="search-student-school-input"
                            type="text"
                            placeholder="Type your class remediation"
                            value={className4}
                            onChange={(e) => handleSchoolName(e, 4)}
                            autoComplete="off"
                          />
                          {activeInput === 4 &&
                            showSchoolNameContainer &&
                            schoolNameList.length > 0 && (
                              <ul
                                className="maintain-group-student-dropdown-menu"
                                style={{
                                  width: "100%",
                                  maxWidth: "740px",
                                  maxHeight: "320px",
                                  overflowY: "scroll",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  boxSizing: "border-box",
                                }}
                              >
                                {schoolNameLoading && <CustomLoader />}
                                {schoolNameList.map((item, index) => (
                                  <li
                                    onClick={() =>
                                      handleSelectedSchoolName(item, 4)
                                    }
                                    key={index}
                                    className="flex admin-school-list cursor"
                                  >
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How do you take this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionHow4}
                          onChange={(e) =>
                            setSelectedOptionHow4(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="I'm taking the course by myself">
                            I'm taking the course by myself
                          </option>
                          <option value="I'm getting tutoring">
                            I'm getting tutoring
                          </option>
                          <option value="I have enrolled in an outside program">
                            I have enrolled in an outside program
                          </option>
                          <option value="I need help taking the class">
                            I need help taking the class
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          When do you complete this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionWhen4}
                          onChange={(e) =>
                            setSelectedOptionWhen4(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="Now">Now</option>
                          <option value="Next semester">Next Semester</option>
                          <option value="Summer">Summer</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          Second class that needs remediation
                        </Label>
                        <div>
                          <Input
                            ref={fifthClassRef}
                            className="search-student-school-input"
                            type="text"
                            placeholder="Type your class remediation"
                            value={className5}
                            onChange={(e) => handleSchoolName(e, 5)}
                            autoComplete="off"
                          />
                          {activeInput === 5 &&
                            showSchoolNameContainer &&
                            schoolNameList.length > 0 && (
                              <ul
                                className="maintain-group-student-dropdown-menu"
                                style={{
                                  width: "100%",
                                  maxWidth: "740px",
                                  maxHeight: "320px",
                                  overflowY: "scroll",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  boxSizing: "border-box",
                                }}
                              >
                                {schoolNameLoading && <CustomLoader />}
                                {schoolNameList.map((item, index) => (
                                  <li
                                    onClick={() =>
                                      handleSelectedSchoolName(item, 5)
                                    }
                                    key={index}
                                    className="flex admin-school-list cursor"
                                  >
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How do you take this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionHow5}
                          onChange={(e) =>
                            setSelectedOptionHow5(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="I'm taking the course by myself">
                            I'm taking the course by myself
                          </option>
                          <option value="I'm getting tutoring">
                            I'm getting tutoring
                          </option>
                          <option value="I have enrolled in an outside program">
                            I have enrolled in an outside program
                          </option>
                          <option value="I need help taking the class">
                            I need help taking the class
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          When do you complete this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionWhen5}
                          onChange={(e) =>
                            setSelectedOptionWhen5(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="Now">Now</option>
                          <option value="Next semester">Next Semester</option>
                          <option value="Summer">Summer</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          Third class that needs remediation
                        </Label>
                        <div>
                          <Input
                            ref={sixthClassRef}
                            className="search-student-school-input"
                            type="text"
                            placeholder="Type your class remediation"
                            value={className6}
                            onChange={(e) => handleSchoolName(e, 6)}
                            autoComplete="off"
                          />
                          {activeInput === 6 &&
                            showSchoolNameContainer &&
                            schoolNameList.length > 0 && (
                              <ul
                                className="maintain-group-student-dropdown-menu"
                                style={{
                                  width: "100%",
                                  maxWidth: "740px",
                                  maxHeight: "320px",
                                  overflowY: "scroll",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  boxSizing: "border-box",
                                }}
                              >
                                {schoolNameLoading && <CustomLoader />}
                                {schoolNameList.map((item, index) => (
                                  <li
                                    onClick={() =>
                                      handleSelectedSchoolName(item, 6)
                                    }
                                    key={index}
                                    className="flex admin-school-list cursor"
                                  >
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How do you take this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionHow6}
                          onChange={(e) =>
                            setSelectedOptionHow6(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="I'm taking the course by myself">
                            I'm taking the course by myself
                          </option>
                          <option value="I'm getting tutoring">
                            I'm getting tutoring
                          </option>
                          <option value="I have enrolled in an outside program">
                            I have enrolled in an outside program
                          </option>
                          <option value="I need help taking the class">
                            I need help taking the class
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          When do you complete this?
                        </Label>
                        <Input
                          type="select"
                          className="custom-select"
                          value={selectedOptionWhen6}
                          onChange={(e) =>
                            setSelectedOptionWhen6(e.target.value)
                          }
                        >
                          <option value="Select">Select</option>
                          <option value="Now">Now</option>
                          <option value="Next semester">Next Semester</option>
                          <option value="Summer">Summer</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          Your current post-high school plans?
                        </Label>
                        <Input
                          type="textarea"
                          className="custom-textarea"
                          value={highSchool}
                          onChange={(e) => sethighSchool(e.target.value)}
                          rows="2"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How are you doing in your classes?
                        </Label>
                        <Input
                          type="textarea"
                          className="custom-textarea"
                          value={doing}
                          onChange={(e) => setDoing(e.target.value)}
                          rows="2"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          What can we do to help you?
                        </Label>
                        <Input
                          type="textarea"
                          className="custom-textarea"
                          value={help}
                          onChange={(e) => setHelp(e.target.value)}
                          rows="2"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          What can we do to help your success?
                        </Label>
                        <Input
                          type="textarea"
                          className="custom-textarea"
                          value={success}
                          onChange={(e) => setSuccess(e.target.value)}
                          rows="2"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          Are you having any difficulties in your classes?
                        </Label>
                        <Input
                          type="textarea"
                          className="custom-textarea"
                          value={difficulties}
                          onChange={(e) => setDifficulties(e.target.value)}
                          rows="2"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          How can your advisior better help you in your current
                          challenges?
                        </Label>
                        <Input
                          type="textarea"
                          className="custom-textarea"
                          value={challenges}
                          onChange={(e) => setChallenges(e.target.value)}
                          rows="2"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup className="position-relative">
                        <Label className="labelBold">
                          What are your immediate top three goals for this
                          semester?
                        </Label>
                        <Input
                          type="textarea"
                          className="custom-textarea"
                          value={threeGoals}
                          onChange={(e) => setThreeGoals(e.target.value)}
                          rows="2"
                          placeholder="Type here"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">&nbsp;</Col>
                    <Col lg="6">
                      <div className="d-flex justify-content-end btnWrapper">
                        <div className="btnInner">
                          <CommonButton
                            name="Cancel"
                            btnClass="primaryBtn w-100 passionBtn"
                            onClick={() => history.goBack()}
                          />
                        </div>
                        <div className="btnInner">
                          <CommonButton
                            name="Save Reflection"
                            style={{
                              opacity:
                                (className1 &&
                                  selectedOptionHow1 !== "Select" &&
                                  selectedOptionWhen1 !== "Select") ||
                                (className2 &&
                                  selectedOptionHow2 !== "Select" &&
                                  selectedOptionWhen2 !== "Select") ||
                                (className3 &&
                                  selectedOptionHow3 !== "Select" &&
                                  selectedOptionWhen3 !== "Select") ||
                                (className4 &&
                                  selectedOptionHow4 !== "Select" &&
                                  selectedOptionWhen4 !== "Select") ||
                                (className5 &&
                                  selectedOptionHow5 !== "Select" &&
                                  selectedOptionWhen5 !== "Select") ||
                                (className6 &&
                                  selectedOptionHow6 !== "Select" &&
                                  selectedOptionWhen6 !== "Select") ||
                                highSchool.length > 0 ||
                                doing.length > 0 ||
                                help.length > 0 ||
                                success.length > 0 ||
                                difficulties.length > 0 ||
                                challenges.length > 0 ||
                                threeGoals.length > 0
                                  ? 1
                                  : 0.5,
                              cursor:
                                (className1 &&
                                  selectedOptionHow1 !== "Select" &&
                                  selectedOptionWhen1 !== "Select") ||
                                (className2 &&
                                  selectedOptionHow2 !== "Select" &&
                                  selectedOptionWhen2 !== "Select") ||
                                (className3 &&
                                  selectedOptionHow3 !== "Select" &&
                                  selectedOptionWhen3 !== "Select") ||
                                (className4 &&
                                  selectedOptionHow4 !== "Select" &&
                                  selectedOptionWhen4 !== "Select") ||
                                (className5 &&
                                  selectedOptionHow5 !== "Select" &&
                                  selectedOptionWhen5 !== "Select") ||
                                (className6 &&
                                  selectedOptionHow6 !== "Select" &&
                                  selectedOptionWhen6 !== "Select") ||
                                highSchool.length > 0 ||
                                doing.length > 0 ||
                                help.length > 0 ||
                                success.length > 0 ||
                                difficulties.length > 0 ||
                                challenges.length > 0 ||
                                threeGoals.length > 0
                                  ? "pointer"
                                  : "default",
                            }}
                            disabled={
                              !(
                                (className1 &&
                                  selectedOptionHow1 !== "Select" &&
                                  selectedOptionWhen1 !== "Select") ||
                                (className2 &&
                                  selectedOptionHow2 !== "Select" &&
                                  selectedOptionWhen2 !== "Select") ||
                                (className3 &&
                                  selectedOptionHow3 !== "Select" &&
                                  selectedOptionWhen3 !== "Select") ||
                                (className4 &&
                                  selectedOptionHow4 !== "Select" &&
                                  selectedOptionWhen4 !== "Select") ||
                                (className5 &&
                                  selectedOptionHow5 !== "Select" &&
                                  selectedOptionWhen5 !== "Select") ||
                                (className6 &&
                                  selectedOptionHow6 !== "Select" &&
                                  selectedOptionWhen6 !== "Select") ||
                                highSchool.length > 0 ||
                                doing.length > 0 ||
                                help.length > 0 ||
                                success.length > 0 ||
                                difficulties.length > 0 ||
                                challenges.length > 0 ||
                                threeGoals.length > 0
                              )
                            }
                            btnClass="primaryBtn w-100 passionBtn"
                            onClick={() => handleReflectionSubmit()}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Reflection;
